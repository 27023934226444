 //import stuff
    import React, { Component } from 'react';
    
    //the class you are making your component from
    class AboutModal extends Component {
      // constructor to set state and bind "this"
      constructor(props) {
          super(props);
          this.state = {showModal: false};
          this.handleClick = this.handleClick.bind(this);
        }
    
      // function to handle the click
       handleClick() {
        this.setState(prevState => ({
          showModal: !prevState.showModal
        }));
      }
      
      // the render() method to put stuff into the DOM
      render() {
        // the modal you will toggle on and off
        const modal = (
          <div className="modal">
            Hello, my name is Godzilla
          </div>
        );
        
        // the return() to put your default HTML into the DOM
        return (
            // wrapper div of component
            <div className="about">
              // 
              <div className='button' onClick={this.handleClick}>
                Open Modal
              </div>
              // where you want the modal to show up
              {this.state.showModal ? modal : ''} 
            </div>
        );
      }
    }
    // export the class so you can call it elsewhere
    export default AboutModal;